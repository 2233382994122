/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductBundleOptions {
    &-Wrapper {
        overflow: hidden;
        text-align: center;
        display: block;

        & .ProductBundleItem-Wrapper {
            &Left {
                position: relative;

                @include mobile {
                    float: inline-start;
                }

                @include desktop {
                    float: inline-end;
                }

                width: 66%;
            }

            &Right {
                position: relative;
                float: inline-start;
                width: 25%;
            }
        }

        & .FieldGroup-Wrapper {
            & .Field-Wrapper {
                & .input-control, &_type_numberWithControls {
                    display: none;
                }

                & .FieldRadio-label {
                    position: relative;
                    z-index: 0;
                    //margin-block-end: 5px;
                    display: inline-block;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 14px;
                    font-family: futura-pt-book,Arial, Helvetica, sans-serif;
                    color: #223041;
                    padding: 0 3px;
                }

                & .Field-RadioLabel_isChecked {
                    border: 1px solid #000;
                }

                & .Field-RadioLabel_isDisabled {
                    & .FieldRadio-label {
                        color: grey !important;
                    }
                }
            }

            & .ProductBundleItem-Radio {
                display: inline-block;
                width: 25px;
                height: 25px;
            }
        }

        & .ProductBundleItem-Radio_isNone {
            & .FieldRadio-label {
                text-align: center;
                border: 1px solid #d44d4d;
                margin: 1px;
                color: #d44d4d !important;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                vertical-align: middle;
                position: relative;
                line-height: 16px;
            }
        }
    }
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
    .ProductBundleItem-Wrapper {
        width: 49%;
        float: inline-start;
    }
}

@media only screen and (min-width: 768px) {
    .ProductBundleOptions {
        &-Wrapper {
            & .ProductBundleItem-Wrapper {
                &Right {
                    width: 32%;
                    margin-block-start: 10px;
                }
            }
        }
    }
}
