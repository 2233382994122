/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBProductoAsociado {
    &-Popup {
        .Popup-Content {
            width: 530px;

            .Popup-Header {
                .Popup-Heading {
                    display: none;
                }
            }

            .content {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .item-product {
                    width: 50%;

                    .ProductCard-Footer .Button.AddToCart span {
                        background: #272f66;
                        color: #fff;
                        -webkit-appearance: button;
                        cursor: pointer;
                        width: fit-content;
                        height: 25px;
                        padding: 3px 10px;
                    }
                }

                .PopupProductoAsociado-CloseBtn {
                    margin-block-start: 20px;
                    -webkit-appearance: button;
                    color: #00295a;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    border: 1px solid #272f66;
                    cursor: pointer;
                    width: fit-content;
                    padding: 5px;
                }

                .texto-producto-asociado {
                    text-align: center;
                    margin-block-end: 50px;
                    color: #00295a;
                    font-size: 16px;
                }
            }
        }
    }
}
