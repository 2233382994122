/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image
.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: column;
    height: 529px;

    @include narrow-desktop {
        min-width: 507px;
    }

    @include mobile {
        margin-block-end: 8px;
        margin-inline: 0px;
    }

    &-Additional {
        flex: 0 0 auto;

        @include mobile {
            display: none;
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }

        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;
        height: 100%;
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);

        &_isImageZoomPopupActive {
            cursor: pointer;

            .Slider-Wrapper {
                align-items: center;
            }
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }

        .Slider-Crumbs {
            .Slider-Crumb {
                background-color: rgba(34, 48, 65, 0.2);

                &_isActive {
                    width: 8px;
                    height: 8px;
                    background-color: rgba(34, 48, 65, 1);
                }
            }
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;

        @include mobile {
            img {
                object-fit: contain;
            }
        }
    }

    &-VideoBlock {
        width: 100%;
        pointer-events: none;
    }
}

.Slider-Wrapper.Draggable.targetDragSlider {
    .ProductGallery {
        &-VideoBlock {
            pointer-events: all;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .ProductGallery {
            &-Slider {
                .Slider-Crumbs {
                    inset-block-end: 20px;
                }
            }
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}

@media only screen and (min-width: 768px) {
    .ProductGallery {
        flex-direction: row-reverse;

        &-Additional {
            &::-webkit-scrollbar {
                display: none;
            }

            scrollbar-width: none;
            overflow: auto;
        }

        &-ybbarra {
            position: absolute;
            inset-block-start: 0px;
            width: 5px;
            background-color: #081d4b;
            inset-inline-end: 0px;
            transition: all .5s;
            border-radius: 1px;
        }

        &ThumbnailImage {
            padding: 0px;
        }

        &-SliderImage {
            width: max-content;

            .Image-Image {
                height: 100%;
                position: relative;
            }
        }

        &-Slider {
            .Slider-Crumbs {
                display: none;
            }
        }

        .CarouselScroll {
            margin-block-start: 0px;
            align-items: inherit;
            
            &-ContentWrapper {
                width: auto;
            }

            &-Content {
                flex-direction: column;
            }
        }

        .CarouselScrollItem {
            flex: auto;
            width: 90px;
            min-height: 112px;
            border: none;
            margin-inline-start: var(--carousel-scroll-gap);
            margin-inline-end: 10px;

            &_isActive {
                border: 2px solid #081d4b;
                transition: none !important;
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .ProductGallery {
        height: 450px;
    }
}

@media only screen and (max-width: 320px) {
    .ProductGallery {
        height: 375px;
    }
}
