/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductCustomizableItem-Wrapper {
    margin: 0 10%;

    .Field-Wrapper {
        .Field {
            input {
                width: 100%;
            }
        }
    }

    .react-datepicker-wrapper {
        input {
            width: 100%;
        }

        .react-datepicker__close-icon {
            inset-block-start: 0px;
            position: absolute;
            inset-inline-end: 10px;
        }
    }

    .react-datepicker-popper {
        width: 110%;
    }
}
