/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductListPage {
    &.CategoryProductList-Page {
        display: inline;
        opacity: 1 !important;

        @include mobile {
            padding-inline-start: 0;
            padding-inline-end: 0;

            &.elem4 li .ProductCard, &.elem3 li .ProductCard {
                &-ProductTag {
                    width: 60%;
                }
            }
        }

        li.ProductCard {
            display: inline-block;
            padding: 0 7.5px;
            
            &.creatividad {
                padding: 0;
            }
        }

        &.elem1 li {
            width: 100%;
            max-width: 960px;
        }

        &.elem2 li {
            width: 50%;
        }

        &.elem3 li {
            width: 33.33%;
        }

        &.elem4 li {
            width: 25%;
        }

        &.elem5 li {
            width: 20%;
        }

        &.elem6 li {
            width: 16.66%;
        }

        &.elem7 li {
            width: 14.285714286%;
        }
        
        &.elem8 li {
            width: 12.5%;
        }
    }
}
