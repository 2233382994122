/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductLinks {
    &-Title {
        border-bottom: 1px solid rgba(52, 64, 121, 0.6);
        margin: 0 15px 15px;
        padding: 10px;
        font-family: 'futura-pt-demi', sans-serif;
        font-size: 15pt;
        color: #0d233f;
        text-transform: inherit;
        font-weight: 100;
    }
    
    &-List {
        padding: 0 15px;
    }
}
