/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.overscrollPrevented {
    overscroll-behavior: contain;
}

.YBProductGallery {
    display: block;
    width: 100%;
    overflow: hidden;
    min-height: 350px;
    max-width: calc(100% - 350px);
    position: relative;
    float: inline-start;

    &-SlideShow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4px;
    }

    &-ImgBlock {
        cursor: pointer;
    }

    &-VideoBlock {
        grid-column: 1 / 3;
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
    }
}

/* Animation */

@keyframes fadeInUp {
    from {
        transform: translate3d(0,500px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,500px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
