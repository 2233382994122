/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBRelatedProducts {
    padding-block-start: 30px;

    .block-content {
        display: grid;
        grid-gap: 14px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0 15px;

        .ProductCard-LoadPicture {
            margin-block-end: 30px;
            height: 380px;

            @include mobile {
                height: 280px;
            }
        }
    }

    .block-content .ProductCard {
        width: 100%;
    }

    .block-title {
        font-family: 'futura-pt-demi', sans-serif;
        font-size: 15pt;
        color: #0d233f;
        text-align: start;
        padding-inline-start: 10px;
        padding-block-end: 10px;
        border-bottom: 1px solid rgba(52,64,121,.6);
        margin: 0 15px 15px;
        font-weight: 100;
    }
}

.CartOverlay-YBRelatedProducts {
    .block-content {
        .ProductCard-LoadPicture {
            margin-block-end: 15px;
            height: 240px;
        }

        .ProductCard-LoadPicture:nth-child(3), .ProductCard-LoadPicture:nth-child(4) {
            display: none;
        }
    }
}

@media only screen and (max-width: 728px) {
    .YBRelatedProducts {
        .block-content {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .YBRelatedProducts {
            padding-block-end: 100px;
        }
    }
  
    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}
