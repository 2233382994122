/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;

    @include desktop {
        padding-block-start: 0px;
        padding-block-end: 0px;
    }

    &-DropDownList {
        text-align: center;
        margin-block-start: 2px;
        margin-block-end: 12px;
    }

    &-Title {
        font-weight: 700;
        margin-block: 24px 12px;
        display: none;

        &_isUnselected {
            animation: var(--shake-animation);
        }
    }

    &-SelectedOptionLabel {
        font-weight: normal;
        opacity: 0.7;
        padding-inline-start: 17px;
    }

    &-SwatchList {
        text-align: start;
        margin-block-start: 2px;
        margin-block-end: 0px;
        display: block;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding-inline-start: 3px;
        padding-block-start: 3px;

        @include desktop {
            text-align: center !important;
        }

        &_isUnselected {
            animation: var(--shake-animation);
        }

        &_attribute_code_size {
            margin-inline-start: 0;
            margin-block-start: 8px;
        }
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block-end: var(--option-margin-block);
        margin-block-start: var(--option-margin-block);
        margin-inline-end: var(--option-margin-inline);
        margin-inline-start: var(--option-margin-inline);
    } 

    &-Expandable:first-of-type {
        border-block-start: none;
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: none;
    }
}

.ProductCard-Attributes {
    .ProductConfigurableAttributes {    
        &-SwatchList {
            text-align: center;

            &_attribute_code_size {
                padding-block-start: 5px;
                margin-inline-start: 0px;
            }

            &_attribute_code_color {
                margin-block-start: 5px;
            }
        }
    }
}
