/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductActions {
    @include mobile {
        margin-inline-start: 15px;
    }

    &-Title {
        @include desktop {
            text-align: center;
            font: 18px 'futura-pt-demi',sans-serif;
            margin-block-end: 0;
            letter-spacing: normal;
        }
    }

    &-ReviewText {
        @include desktop {
            display: none;
        }
    }

    &-AttributesWrapper {
        @include mobile {
            margin-block-end: 0px;
        }
    }

    &-ActionsWrapper {
        .ProductActions-Title {
            color: #4b4b4b;

            @include mobile {
                margin-block-start: 10px;
                text-transform: uppercase;
                font: 14px/14px "futura-pt-demi", sans-serif;
                font-weight: 700;
            }
        }

        .ProductActions-PriceWrapper {
            .ProductPrice {
                del {
                    @include mobile {
                        padding-block: 1px 0px;
                        color: #4b4b4b;
                        font-weight: 700;
                        font-family: "futura-pt-heavy", sans-serif;
                        display: block;
                    }
                }

                .ProductPrice-PriceValue {
                    @include mobile {
                        padding-block: 1px 0px;
                        color: #4b4b4b;
                        font-weight: 700;
                        font-family: "futura-pt-heavy", sans-serif;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &-AddToCartWrapper {
        @include desktop {
            justify-content: center;
        }

        @include mobile {
            justify-content: space-between;
        }
        
        margin-block-start: 20px;

        @include mobile {
            margin-inline-end: 20px;
        }

        .ProductActions-AddToCart {
            width: 170px;

            @include desktop {
                width: 65%;
                margin-inline-end: 24px;
            }

            height: 38px;
            background-color: #0f233f;
        }

        .ShowPopupAddToCart {
            width: 170px;

            @include desktop {
                width: 74%;
            }

            height: 38px;
            background-color: #0f233f;
            font-size: 16px;
            padding: 7px 30px;
            letter-spacing: 1px;

            @include desktop {
                margin: 0 auto;
                font-weight: 700;
                font-family: 'futura-pt-heavy', Arial, Helvetica, sans-serif;
            }

            span {
                color: white;
            }

            .faEnvelope {
                margin-inline-start: 10px;
                margin-block-end: 3px;
            }

            svg {
                path {
                    fill: var(--color-white);
                }
            }

            &:hover {
                height: 38px;
                background-color: #fff;
                border: 1px solid #0f233f;
    
                span {
                    color: #081d4b;
                }
    
                svg {
                    path {
                        fill: #081d4b;
                    }
                }
            }
        }
    }

    & .preorder-message {
        margin-block-end: 0px;
        margin-block-start: 10px;
        color: #00295A;

        @include desktop {
            text-align: center;
        }
    }

    &-asesoraminetoButton {
        width: 30px;
        height: 30px;
        background-image: url('https://cdn.elganso.com/media/scandiweb/images/video-solid.svg') !important;
        background-size: 100%;
        border: none !important;
        background-repeat: no-repeat;
        background-color: transparent !important;

        @include mobile {
            width: 28px;
            height: 27px;
        }
    }

    &-asesoraminetoMessage {
        display: none;
        position: absolute;
        border: 1px solid rgba(0,41,90,1);
        border-radius: 10px;
        z-index: 10000;
        max-width: 200px;
        background: #fff;
        padding: 15px;
        inset-block-end: -170px;
        inset-inline-end: 20px;
        width: 142px;
        text-align: center;
    }

    &-asesoraminetoOnline {
        cursor: pointer;
        inset-block-start: 5px;

        @include mobile {
            inset-block-start: 0;
            margin-inline-end: 16px;
        }

        button {
            cursor: pointer;
        }

        .title {
            color: #00295a;
            font-family: 'Lato',sans-serif;
            margin: 15px 0;
            font-size: 12px;
        }
    }

    &-asesoraminetoOnline:hover &-asesoraminetoMessage {
        display: block;
    }

    .privacy-policy-link {
        text-align: center;
        margin-block-end: 15px;
        font-weight: 700;
        color: #00295a;
        cursor: pointer;
    }

    .ProductCard-promo {
        background-color: #223041;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 4px 5px;
        width: fit-content;
        margin: 0 auto;

        .priceProductCard {
            font-weight: bold;
            color: white;
        }

        .porcentajeProductCard {
            background-color: red;
            border-radius: 5px;
            color: white;
            padding: 0px 5px 1px;
            margin: 0 0 0 5px;
            font: 12px 'Lato', sans-serif;
            height: 20px;
            font-weight: 900;
        }
    }
}

@media only screen and (min-width: 768px) {
    .ProductActions {
        &-Stock {
            display: none;
        }

        &-Title {
            text-align: center;
            font-size: 18px;
            font-family: 'futura-pt-demi', Arial, Helvetica, sans-serif;
            margin-block-end: 0;
            letter-spacing: normal;
            color: #4b4b4b;
        }

        &-Section_type_sku {
            display: block;
            text-align: center;
            color: #7d7d7d;
        }

        &-Separator {
            width: 60%;
            border-top: 3px solid #4b4b4b;
            margin: 12px auto 28px;

            @include desktop {
                width: 78%;
                border-top: 1px solid #4b4b4b;
            }
        }

        &-eligeTalla {
            font-family: 'futura-pt-medium', Arial, Helvetica, sans-serif;
            font-size: 16px;
            margin-block-end: 5px;
            text-align: center;
        }

        &-eligeTarjetaRegalo {
            font-family: 'futura-pt-medium', Arial, Helvetica, sans-serif;
            font-size: 16px;
            margin-block-end: 15px;
            padding: 0 10%;
        }

        &-PriceWrapper {
            margin-block-start: 0px;

            .ProductPrice {
                margin-block-start: 10px;
                min-height: 20px;

                &-Value {
                    color: #4b4b4b;
                }
            }
        }

        &-WhishlistPriceActions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-block-start: 10px;
        }

        &-Schema {
            max-width: max-content;
        }

        .ProductWishlistButton {
            position: relative;
            inset-block-start: 8px ;
            display: block;
            inset-inline-start: -10px;

            @include desktop {
                inset-inline-start: -42px !important;
            }

            z-index: 7;
            cursor: pointer;
        }
    }
}
