/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

.YeboyeboEnvioYDevoluciones {
    &-Wrapper {
        padding-inline-start: 0px;
        padding-inline-end: 0px;

        .YeboyeboEnvioYDevoluciones-Envios {
            .ExpandableContent-Heading {
                padding-inline-start:0px;
            }
        }
    }

    &-Envios{
        .AddIcon{
            inset-block-start:  0px;
            margin-inline-end: 10px;
        }

        svg{
            vertical-align: unset;
        }
    }
}
