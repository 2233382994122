/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Product {
    .ProductPageSku {
        font: 16px/20px 'futura-pt-medium',sans-serif;
    }
}

.ProductPageSku {
    font: 14px/20px 'futura-pt-medium',sans-serif;
}

.ProductActions-AttributesWrapper.ProductActionsMobile {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

    #bloque_naizfit {
        margin-inline-end: 20px;
    }
}
