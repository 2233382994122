/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBProductListPage {
    .ActiveProductTittle {
        display: none;
    }

    .Slider {
        &-Crumb {
            border: none !important;
        }

        .Slider-Crumb_isActive {
            width: 10px !important;
            height: 10px !important;
        }
    }

    &-Swiper {
        @include mobile {
            .swiper-slide:not(.swiper-slide-active) {
                max-height: 1000px;
            }
        }
    }

    .ProductAttributeValue-Color::after {
        border: none !important;
    }

    .YeboyeboProducts-ExpandableContentHeading, .YeboyeboEnvioYDevoluciones-Envios .ExpandableContent-Heading, .DisponibilidadEnTienda-Popup .ExpandableContent-Heading, .ProductBci-ExpandableContentButton .ProductBci-ExpandableContentHeading {
        font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 300;
        color: #4b4b4bda;
        line-height: 16px;
    }

    .ExpandableContent .ExpandableContent-Heading.ProductInformation-ExpandableContentHeading {
        font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
    }

    .YeboyeboProducts-ExpandableContentButton, .YeboyeboEnvioYDevoluciones-Envios .ExpandableContent-Heading, .ProductInformation-ExpandableContentButton, .DisponibilidadEnTienda-Popup .ExpandableContent-Heading, .ProductBci-ExpandableContentButton {
        @include mobile {
            padding: 10px 20px !important;
        }

        & svg {
            width: 14px;
            height: 14px;
            inset-block-start: 1px;
        }
    }

    .ProductAttributeValue-String_isPreOrder::after {
        @include desktop {
            inset-block-end: 1px !important;
        }

        @include mobile {
            inset-block-end: -1px !important;
        }

        inset-inline-start: 11px !important;
    }
}

@media (min-width: 768px) {
    .YBProductListPage {
        &-Swiper {
            overflow: hidden;
        }

        .ExpandableContent-Button, .YeboyeboEnvioYDevoluciones, .DisponibilidadEnTienda {
            .ExpandableContent-Heading {
                display: inherit;
            }
        }

        .swiper-slide:not(.swiper-slide-visible) {
            .YBProductGallery {
                max-height: 100vh;
            }
        }

        .ExpandableContent-Heading {
            cursor: pointer;
        }

        .YeboyeboProducts-Content {
            min-width: 200px;
        }

        .swiper-button-next {
            transform: translate3d(15px, 0, 0);
            inset-inline-end: 6px;
            background: linear-gradient(to right,#FFFFFF, #F6F6F6);
        }

        .swiper-button-prev {
            transform: translate3d(-15px, 0, 0);
            inset-inline-start: 3px;
            background: linear-gradient(to left,#F6F6F6, #FFFFFF);
        }

        .swiper-button-next, .swiper-button-prev {
            height: calc(100vh - var(--header-total-height) + 25px);
            width: 40px;
            position: fixed;
            opacity: 0;
            inset-block-start: calc(25px + var(--header-total-height));
            transition: all 200ms ease-out;
            
            &::after {
                color: white;
            }

            &:hover {
                opacity: 1;
                transition: all 200ms ease-in;
                transform: translate3d(0, 0, 0);

                &::after {
                    color: #0f233f;
                }
            }
        }
    }

    .ProductActions-PriceWrapper {
        .ProductPrice-PriceValue {
            color: #4b4b4b !important;
            text-decoration: none !important;
            display: block;
            font: 18px/19px "futura-pt-demi",sans-serif !important;
            width: 100%;
        }

        .ProductPrice-HighPrice {
            color: #4b4b4b !important;
            display: block;
            font: 18px/19px "futura-pt-demi",sans-serif !important;
            width: 100%;
        }
    }
}
