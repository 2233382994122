/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: #{$black};
    --option-size: 14px;
    --option-text-color: #{$black}; // Move to root
    --option-border-color: var(--color-dark-gray);

    @include mobile {
        --option-border-color: var(--color-dark-gray);
    }
}

.ProductAttributeValue_isSelected .ProductAttributeValue {
    &-Label {
        text-decoration: underline;
    }
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;

    &-Color,
    &-Image,
    &-Text {
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin-inline-end: 6px;
        margin-inline-start: 6px;
    }

    &-String {
        padding: 0 8px;
        margin-block-end: 6px;
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: #00295A;
        display: inline-block;
        min-height: var(--option-size);
        margin-inline-end: 0px;
        border-style: none;
        border-radius: var(--button-border-radius);
        line-height: calc(var(--option-size) - 2px);
        min-width: var(--option-size);
        font-weight: 500;
        width: auto;
        text-align: center;
        font-size: 12px;

        &_isSelected {
            border-style: solid;
            padding: 6px;
            --option-text-color: var(--primary-base-color);
            --option-border-color: var(--primary-base-color);

            font-weight: 600;
        }

        &_isPreOrder::after {
            content: '.';
            font-size: 4px;
            font-weight: 600;
            border-radius: 100%;
            position: absolute;
            background-color: red;
            inset-block-end: 0;
            inset-inline-start: 41%;

            @include mobile {
                inset-block-end: -2px; 
            }

            width: 4px;
            height: 4px;
        }

        &_isBajoStock::after {
            content: '.';
            font-size: 4px;
            font-weight: 600;
            border-radius: 100%;
            position: absolute;
            background-color: #00295a;
            inset-block-end: 0;
            inset-inline-start: 41%;

            @include mobile {
                inset-block-end: -2px; 
            }

            width: 4px;
            height: 4px;
        }

        &_isNotAvailable::after {
            content: '.';
            font-size: 4px;
            font-weight: 600;
            border-radius: 100%;
            position: absolute;
            background-color: #00295a;
            inset-block-end: 0;
            inset-inline-start: 10px;
            
            @include mobile {
                inset-inline-start: 7px; 
            }

            width: 4px;
            height: 4px;
        }

        span {
            font-size: 14px;
        }
    }

    &-String:hover {
        position: relative;
        z-index: 1;
    }

    &-String[data-title]:hover::after {
        content: attr(data-title);
        width: max-content;
        padding: 8px !important;
        position: absolute;
        inset-inline-start: -100%;
        inset-block-start: 100%;
        color: #00295a;
        background: white;
        border: 1px solid #00295a;
        font-size: 12px;
        border-radius: initial;
        text-transform: uppercase;
        height: min-content;
        font-weight: 400;
        font-family: inherit;

        @include mobile {
            display: none !important;
        }
    }

    &-Image {
        border-radius: 50%;
        object-fit: cover;
        padding: 0;
        position: relative;
    }
    
    &-Image-Overlay {
        --option-check-mark-background: var(--color-white);

        inset-block-start: 0;
        inset-inline-start: 0;
        margin: 0;
        position: absolute;
        border-radius: 50%;
        font-size: 0;
        width: var(--option-size);
        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;
            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-inline-start, border-inline-end;
        }

        &::before {
            height: calc(var(--option-size) + 6px);
            width: calc(var(--option-size) + 6px);
            border-radius: inherit;
            box-shadow: inset 0 0 0 1px var(--primary-base-color);
            inset-inline-start: -3px;
            inset-block-start: -3px;
        }

        &::after {
            height: calc(var(--option-size) / 5);
            inset-inline-start: calc(var(--option-size) / 3.3);
            inset-block-start: calc(var(--option-size) / 3.3);
            width: calc(var(--option-size) / 2.5);
            transform: rotate(-45deg);

            [dir="rtl"] & {
                transform: scale(-1, 1) rotate(45deg);
            }

            border-inline-start: 2px solid var(--option-check-mark-background);
            border-block-end: 2px solid var(--option-check-mark-background);
        }

        &:hover {
            @include desktop {
                &::before,
                &::after {
                    --option-is-selected: 1;
                }
            }
        }
    }

    &-Color {
        box-shadow: inset 0 0 0 1px #979797;
        box-shadow: inset 0 0 0 1px var(--option-border-color);
        border-radius: 50%;
        font-size: 0;
        width: var(--option-size);
        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;

            @include mobile {
                opacity: var(--option-is-selected);
            }

            transition: opacity 200ms;
            will-change: opacity, border-inline-start, border-inline-end;
        }

        &::before {
            height: calc(var(--option-size) + 6px);
            width: calc(var(--option-size) + 6px);
            border-radius: inherit;
            box-shadow: inset 0 0 0 1px #00295A;
            inset-inline-start: -3px;
            inset-block-start: -3px;
        }

        &::after {
            display: none;
            height: calc(var(--option-size) / 5);
            inset-inline-start: calc(var(--option-size) / 3.3);
            inset-block-start: calc(var(--option-size) / 3.3);
            width: calc(var(--option-size) / 2.5);
            transform: rotate(-45deg);

            [dir="rtl"] & {
                transform: scale(-1, 1) rotate(45deg);
            }

            border-inline-start: 2px solid var(--option-check-mark-background);
            border-block-end: 2px solid var(--option-check-mark-background);
        }

        &:hover {
            @include desktop {
                &::before,
                &::after {
                    --option-is-selected: 1;
                }
            }
        }
    }

    &-Text {
        border-style: solid;
        border-radius: var(--button-border-radius);
        line-height: calc(var(--option-size) - 2px);
        min-width: var(--option-size);
        font-weight: 400;
        width: auto;
        text-align: center;

        &_isSelected {
            --option-border-color: var(--primary-base-color);

            font-weight: 600;
        }

        span {
            font-size: 14px;
        }
        
        display: flex;
        border: 0;
        align-items: baseline;
        margin: 0;

        label,
        .input-control {
            font-weight: 400;
            cursor: pointer;
        }

        label {
            &:first-of-type {
                padding-block-end: 0;
                order: 1;
                text-align: start;
                overflow: hidden;
                text-overflow: ellipsis;

                //display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                line-height: 24px;

                .input-control {
                    inset-inline-start: 0;
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    min-height: 18px;
                    vertical-align: top;
                    border-color: var(--color-karaka);

                    @include desktop {
                        align-self: self-start;
                        margin-block-start: 4px;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            label {
                @include desktop {
                    border-color: var(--primary-base-color);
                }
            }

            .input-control {
                &::after {
                    @include desktop {
                        --checkmark-color: var(--secondary-dark-color);
                    }
                }
            }
        }

        @include desktop {
            padding-block-end: 6px;
            padding-inline: 0;
        }
    }

    &:hover {
        @include desktop {
            --option-border-color: var(--primary-base-color);
            --option-text-color: var(--primary-base-color);
        }

        text-decoration: none;
    }

    .fa-envelope {
        display: none;
    }

    &_isNotAvailable {
        opacity: unset;
        cursor: default;
        pointer-events: unset;

        .ProductAttributeValue-Color {
            &::before {
                inset-inline-start: 7px;
                height: 13px;
                background-color: var(--option-check-mark-background);
                opacity: 0;
            }
        }

        .ProductAttributeValue-String {
            color: #ccc !important;
            cursor: pointer!important;

            &::after {
                font-family: var(--fa-style-family, "Font Awesome 6 Pro");
                content: var(--pruebas);
                font-weight: 400;
                background: 0 0;
                font-size: 8px;
                width: 100%;
                text-align: end;
                padding-inline-end: 0;
                position: relative;
                inset-block-start: -10px;
            }
        }

        .fa-envelope {
            display: inherit;
            position: absolute;
            inset-block-start: -3px;
            inset-inline-end: -1px;
            z-index: 5;
            width: 8px;
            height: 8px;

            @include desktop {
                inset-inline-end: 1px;
            }

            path {
                fill: #ccc !important;
            }
        }
    }

    &-SubLabel {
        font-weight: bold;
        font-size: 12px;
        white-space: break-spaces;
        padding-inline-start: 4px;
    }
}

.CategoryPage, .YBRelatedProducts, .CmsPage {
    .ProductCard {
        .ProductAttributeValue-String {
            font-size: 10px;
            padding: 0 5px;
        }

        .ProductAttributeValue-Color {
            min-height: 9px;
            width: 9px;
        }

        .ProductAttributeValue-Color::before {
            height: 15px;
            width: 15px;
        }

        .ProductAttributeValue-Color::after {
            border-left: none;
            border-bottom: none;
        }

        .ProductConfigurableAttributes-SwatchList_attribute_code_size {
            margin-block-start: 0;
        }
    }
}

.ProductCard {
    .ProductAttributeValue {
        &-String {
            &_isSelected {
                border-style: none;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .YBRelatedProducts, .CmsPage {
        .ProductAttributeValue:not(.ProductAttributeValue_isNotAvailable) {
            .ProductAttributeValue-String {
                &:hover {
                    outline: 1px solid #999;
                }
            }
        }

        .ProductAttributeValue {
            &-String {
                font-size: 12px;
                padding: 6px 5px;
                margin: 0 2px;
            }
        }
    }

    .CategoryPage, .YBRelatedProducts, .CmsPage {
        .ProductCard {
            .ProductAttributeValue:not(.ProductAttributeValue_isNotAvailable) {
                .ProductAttributeValue-String {
                    &:hover {
                        outline: 1px solid #999;
                    }
                }
            }

            .ProductAttributeValue {
                &-String {
                    font-size: 12px;
                    padding: 6px 5px;
                    margin: 0 2px;
                }

                &-Color {
                    min-height: 14px;
                    width: 14px;
                    border: 1px solid #00295a;
                    box-shadow: none !important;

                    &::before {
                        border-radius: 50%;
                        content: "";
                        position: absolute;
                        inset-block-start: 0;
                        inset-inline-start: 0;
                        inset-inline-end: 0;
                        inset-block-end: 0;
                        z-index: 3;
                        border: 2px solid #fff;
                        height: auto;
                        width: auto;
                    }
                }
            }
        }
    }

    .YBProductListPage, .CmsPage {
        .ProductConfigurableAttributes-SwatchList_attribute_code_color {
            .ProductAttributeValue {
                &-Color {
                    &::after {
                        content: "";
                        display: none;
                    }
                }
            }
        }

        .ProductActions .ProductConfigurableAttributes-SwatchList_attribute_code_size {
            .ProductAttributeValue {
                &-String {
                    color: #00295a;
                    padding: 12px 6px 7px;
                    cursor: pointer;
                    font-weight: 600;
                    background: unset;
                    font-size: 14px;
                    font-family: 'futura-pt-book', Arial, Helvetica, sans-serif;
                    height: unset;
                    min-width: 26px;

                    &:hover {
                        border-style: solid;
                        margin: -1px;
                    }

                    &_isSelected {
                        margin: -1px;
                    }
                }
            }
        }
    }
}
