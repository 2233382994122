/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

.YeboyeboProducts {
    .YeboyeboProducts-Wrapper{
        padding-inline-start: 0px;
        padding-inline-end: 0px;
    }
    &-Composicion {
        font-family: Lato, sans-serif;
        @include mobile {
            margin-inline-start: 30px;
            margin-inline-end: 30px;
            line-height: 18px;
            text-align: start;
            color: #223041;
            font-weight: 600;
        }
    }
    &-Envios{
        padding-inline-start: 0px;
        padding-left:0px;
        .AddIcon{
            top: 3px;
            margin-right: 13px;
        }
    }
}
