/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductPage {
    @include mobile {
        padding-block-end: 30px;
        margin-inline: 0px;
    }

    .ProductGallery-SliderWrapper {
        overscroll-behavior: contain;
        overflow: auto;
    }

    .ProductGallery-SliderWrapper_isLastImage {
        overflow: hidden;
    }

    .YeboyeboProducts-ExpandableContentHeading, .YeboyeboEnvioYDevoluciones-Envios .ExpandableContent-Heading, .DisponibilidadEnTienda-Popup .ExpandableContent-Heading {
        font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #4b4b4bda;
        line-height: 16px;
    }

    .ExpandableContent .ExpandableContent-Heading.ProductInformation-ExpandableContentHeading {
        font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
    }

    .YeboyeboProducts-ExpandableContentButton, .YeboyeboEnvioYDevoluciones-Envios .ExpandableContent-Heading, .ProductInformation-ExpandableContentButton, .DisponibilidadEnTienda-Popup .ExpandableContent-Heading {
        padding: 14px 20px !important;

        & svg {
            width: 10px;
            height: 10px;
        }
    }
}
