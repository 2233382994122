/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBProductPage {
    @include mobile {
        margin-inline: 0px;
    }

    .ProductGallery {
        overflow: auto;
    }

    .ProductGallery-SliderWrapper {
        overscroll-behavior-y: contain;
        overflow: auto;
    }

    .ProductGallery-SliderWrapper_isLastImage {
        overflow: hidden;
    }

    &-Wrapper {
        padding: 0;
    }

    &-LoadRelated {
        display: grid;
        grid-gap: 14px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 0 15px 15px;
        height: 380px;

        @include mobile {
            grid-template-columns: 1fr 1fr;
            height: 480px;
        }
    }

    .GuiaDeTallas {
        .GuiaDeTallas-Wrapper {
            padding-inline-start: 0px;
            padding-inline-end: 0px;
    
            .GuiaDeTallas {
                .ExpandableContent-Heading {
                    padding: 10px 20px !important;
                    font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    color: #4b4b4bda;
                    line-height: 16px;

                    .AddIcon {
                        @include mobile {
                            width: 14px;
                            height: 14px;
                            inset-block-start: 1px;
                        }
            
                        @include desktop {
                            inset-block-start: 1px;
                            margin-inline-start: 0px;
                        }
                    }

                    .textSizeGuide {
                        padding-inline-start: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
    .YBProductPage {
        .ProductGallery {
            width: 75%;
            margin: 0 auto;
        }
    }
}

@media only screen and (min-width: 768px) {
    .YBProductPage {
        .ProductTabs-Wrapper {
            padding-inline-start: 50px;
            padding-inline-end: 15px;
        }

        &-left {
            width: 350px;
            display: block;
            position: fixed;
            inset-inline-end: 50px;
            inset-block-start: 0;

            .AddToCart {
                inset-inline-start: 15px;
            }
        }

        .ProductTabs-Section {
            width: 350px;
            float: inline-end;
        }

        &-Wrapper {
            display: block;
            overflow: hidden;
            max-width: 92%;
        }

        .ProductGallery {
            overflow: hidden;
            width: calc(100% - 500px);
            min-height: 600px;
            padding-inline-start: 25px;
            padding-inline-end: 25px;
            margin-block-end: 30px;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            float: inline-start;
        }

        .ProductActions {
            width: 350px;
            margin: 0;
            float: inline-start;
            min-height: 450px;
            padding-inline-start: 17px;

            @include desktop {
                min-height: 350px;
            }

            display: flex;
            align-items: stretch;
            flex-direction: column;
            justify-content: center;

            &_isBundle {
                height: 770px !important;

                .ProductActions-Separator {
                    display: none !important;
                }
            }
        }

        .ProductGallery-SliderWrapper {
            overflow: hidden;
        }

        .AddToCart {
            span {
                color: inherit;
            }

            &:hover {
                height: 38px;
                background-color: #fff;
                color: #081d4b;

                @include mobile {
                    width: auto;
                }

                @include desktop {
                    width: 74%;
                }

                border: 1px solid #0f233f;
                display: inline-block;
                cursor: pointer;
                font-size: 16px;
                padding: 7px 30px;
                text-decoration: none;
                text-shadow: 0px 1px 0px #0f233f;
                font-weight: 700;
                font-family: 'futura-pt-heavy', Arial, Helvetica, sans-serif;
                letter-spacing: 1px;
                text-transform: uppercase;
                transition: all .2s ease;
                border-radius: 0;
            }

            height: 38px;

            @include mobile {
                width: auto;
            }

            @include desktop {
                width: 74%;
            }

            background-color: #0f233f;
            border: 1px solid #0f233f;
            display: inline-block;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            padding: 7px 30px;
            text-decoration: none;
            text-shadow: 0px 1px 0px #0f233f;
            font-weight: 700;
            font-family: 'futura-pt-heavy', Arial, Helvetica, sans-serif;
            letter-spacing: 1px;
            text-transform: uppercase;
            transition: all .2s ease;
            border-radius: 0;
        }
    }
}
