/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductBundleOption {
    // TODO style the element
}

/* stylelint-disable-next-line number-max-precision */
.ProductBundleItem {
    &-Wrapper {
        display: block;
        overflow: hidden;
    }

    &-Heading {
        font-weight: 400 !important;
        margin-block-end: 5px !important;
        color: #223041;
        margin-block-start: 16px;
    }

    &-Options {
        min-height: 50px;

        & .FieldGroup_isValid {
            border: none !important;
            padding-inline-start: 0px !important;
        }
    }

    &-Separator {
        & hr {
            border-top: 3px solid #4b4b4b;
            margin-block-start: 15px;
            margin-block-end: 10px;
            opacity: 1;
        }
    }

    &-Sku {
        color: #7d7d7d;
        font: 16px/20px 'futura-pt-medium',sans-serif;
        margin: 5px 0;
    }

    &-Price {
        overflow: hidden;
        margin-block-start: 30px;
        margin-block-end: 20px;
        justify-content: center;

        &Final {
            font: 16px/27px 'futura-pt-demi',sans-serif;
            line-height: 27px;
            font-weight: 600;
            color: #272f66;
            margin-inline-start: 10px;
        }

        &Wishlist {
            & .svg-inline--fa {
                width: 17px;
                height: 17px;
            }
        }
    }

    &-SwachColor {
        background-color: rgb(45, 38, 138);
        width: 16px;
        height: 16px;
        inset-inline-start: 50%;
        border: 1px solid #00295a !important;
        outline: 0px !important;
        border-radius: 50%;

        &::after {
            border-radius: 50%;
            content: "";
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-end: 0;
            z-index: 3;
            border: 2px solid #fff;
        }
    }
}

@media only screen and (min-width: 768px) {
    .ProductBundleItem {
        &-Price {
            //display: block;
            overflow: hidden;
            margin-block-start: 30px;
            margin-block-end: 20px;
            justify-content: center;
    
            &Final {
                color: #4b4b4b;
                text-decoration: none !important;
                display: block;
                font: 18px/19px "futura-pt-demi",sans-serif;
                width: 100%;
            }
    
            &Wishlist {
                .ProductWishlistButton {
                    inset-inline-start: 28px !important;
                }
    
                & .svg-inline--fa {
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }
}
