/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductCard {
    &-ProductActions {
        .ProductWishlistButton {
            inset-block-end: 20px;
            position: absolute;
            inset-inline-end: 10px;
            margin: 0 !important;
        }

        @include mobile {
            .ProductWishlistButton {
                inset-block-end: 10px;
                inset-inline-end: 5px;
                max-width: 20px;
            }

            .svg-inline--fa {
                max-width: 15px;
            }
        }
    }

    &-ProductTag {
        display: flex;
        inset-block-start: 20px;
        position: absolute;
        margin: 0 !important;
        
        @include mobile {
            inset-block-start: 10px;
        }
    }
    
    &-Content {
        background: var(--product-card-background);
        padding-block: 0px 16px;
        display: flex;
        flex-direction: column;

        @include desktop {
            padding-inline: 0px;
            padding-block-end: 0;
        }

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-OutOfStock {
                    p {
                        margin-inline-end: 10px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                    margin-block-end: 16px;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name {
        margin: 5px 0;
        font: 12px/12px "futura-pt-book", sans-serif !important;
        color: #272f66;
        text-align: center;
        white-space: unset;

        @include desktop {
            line-height: 20px;
        }

        & .preorder-title {
            color: red;
        }
    }

    &-Footer {
        align-items: center;
        display: block;
        justify-content: space-between;
        padding-block-start: 0px;
        text-align: center;

        > * {
            margin-inline-end: 0px;
        }

        .Button.AddToCart {
            height: 25px;
            background-color: #fff;
            min-width: unset;
            width: unset;
            border: none;

            span {
                color: #00295a;
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
            }
        }

        .Button.outOfStock {
            height: 25px;
            background-color: #fff;
            min-width: unset;
            width: unset;
            border: none;
            color: #800000;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;

            svg {
                margin-inline-start: 5px;

                path {
                    fill: #800000;
                }
            }
        }

        .ProductCard-promo {
            background-color: #223041;
            color: white;
            font-size: 12px;
            border-radius: 5px;
            padding: 2px 5px;
            width: fit-content;
            margin: 0 auto;

            .priceProductCard {
                font-weight: bold;
                color: white;
            }

            .porcentajeProductCard {
                background-color: red;
                border-radius: 5px;
                color: white;
                padding: 0px 5px 1px;
                margin: 0 0 0 5px;
                font: 12px 'Lato', sans-serif;
                height: 20px;
                font-weight: 900;
            }
        }
    }

    &-Picture:not(.Image_isPlaceholder) {
        padding-block-end: 0%;
    }

    &-FigureReview {
        padding-inline-start: 0;
    }

    .productoagotado {
        color: #800000;
        font: 14px 'Lato', sans-serif;
        font-weight: 300;
        height: 27px;
        display: block;
        width: 100%;
        cursor: pointer;
        
        .faEnvelope {
            width: 14px;
            height: 14px;
            margin-inline-start: 3px;

            path {
                fill: #800000;
            }
        }
    }

    &-ImageCreatividad {
        p {
            float: inline-start;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media (max-width: 767px) {
        .ProductCard {
            &-ImageCreatividad {
                p {
                    float: none !important;
                }
            }
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        //TODO
    }
}

@media only screen and (min-width: 768px) {
    .ProductCard {
        &-LinkInnerWrapper:hover {
            box-shadow: none !important;
        }

        &-FigureReview {
            padding-inline-start: 0px;
            padding-inline-end: 0px;
            padding-block-end: 0px;
        }

        &-Price {
            font-size: 14px;
        }

        &-Name {
            font-size: 14px !important;
        }

        &-FigureContent {
            .ProductConfigurableAttributes-SwatchList_attribute_code_color {
                display: none;
            }
        }
    }
}
