/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductSliderWidget {
    &-Swiper {
        min-height: 50px;
        margin-block-end: 15px;

        .swiper {
            position: initial;
            margin: 0 20px;
        }

        .swiper-button-prev, .swiper-button-next {
            width: 35px;
            height: 35px;
            border-radius: 30px;
            background-color: black;
            background-size: 7px;
            inset-block-start: 40%;

            &::after {
                font-size: 15px;
                opacity: 0.8;
                color: #fff;
                -webkit-font-smoothing: antialiased;
            }
        }

        .swiper-button-prev {
            inset-inline-start: -15px;
        }
        
        .swiper-button-next {
            inset-inline-end: -15px;
        }

        .swiper-pagination {
            position: relative;

            @include desktop {
                inset-block-end: 0;
            }

            &.swiper-pagination-bullets {
                .swiper-pagination-bullet {
                    box-shadow: none;
                    background: #fff;
                    border: 1px solid #0f233f;
                    width: 10px;
                    height: 10px;
                    margin-inline-end: 15px;
                    opacity: 1;

                    &:last-child {
                        margin-inline-end: 0;
                    }
                }

                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background: #0f233f;
                }
            }            
        }
    }
}
