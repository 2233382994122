/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YeboyeboProducts {
    .YeboyeboProducts-Wrapper {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
    }

    &-Composicion {
        font-family: Lato, sans-serif;
        margin-inline-start: 25px;
        margin-inline-end: 10px;
        line-height: 18px;
        text-align: start;
        color: #223041;
        font-weight: 600;

        @include mobile {
            margin-inline-start: 45px;
        }
    }

    &-Envios {
        padding-inline-start: 0px;

        .AddIcon {
            inset-block-start: 3px;
            margin-inline-end: 13px;
        }
    }
}
