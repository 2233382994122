/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductPrice {
    margin-block-end: 0;
    margin-block-start: 0;
    vertical-align: middle;
    opacity: 1;
    font: 12px/12px 'Lato', sans-serif;
    font-weight: 300;
    color: #272f66;
    text-align: center;

    @include desktop {
        min-height: 25px;
    }

    @include mobile {
        font-size: 12px;
    }

    &-Price {
        text-decoration: none;
        display: inline-flex;
        justify-content: center;
        align-items: baseline;
        flex-wrap: wrap;

        @include desktop {
            flex-wrap: nowrap;
        }
    }

    del {
        display: inline-block;
        margin-inline-end: 6px;
        opacity: 1;
        font: 12px/12px "futura-pt-book", sans-serif;
        font-weight: 300;
        color: #272f66;

        @include mobile {
            margin-block-start: 6px;
        }
    }

    &-HighPrice {
        color: var(--price-color);
        line-height: 18px;

        @include desktop {
            line-height: 14px;
        }
    }

    &-PriceValue {
        margin-block-start: 0px;
        font: 12px/14px 'futura-pt-book',sans-serif;
        line-height: 24px;
        font-weight: 400;
        color: #272f66;

        .tag_discount {
            border: 1px solid red;
            color: red;
            font-weight: 700;
            line-height: 12px;
            font-size: 10px;
            margin-inline-start: 3px;
            padding: 0 4px;
            white-space: nowrap;

            @include desktop {
                font-size: 12px;
            }
        }

        @include desktop {
            font: 14px/14px 'futura-pt-book',sans-serif;
            line-height: 28px;
        }

        @include mobile {
            margin-block-start: 4px;
        }
    }

    &, &-BundleFrom, &-BundleTo {
        &_hasDiscount {
            .ProductPrice {
                &-PriceBadge {
                    margin-block-start: 5px;
                    margin-block-end: -5px;
                }
            }
        }
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-block-start: 4px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;

        @include desktop {
            line-height: 20px;
        }
    }
}

.YBProductPage {
    .ProductPrice {
        @include mobile {
            font-size: 12px;
            text-align: end;
            margin-inline-end: 20px;
        }
    }
    
    .YBRelatedProducts .ProductPrice {
        @include mobile {
            text-align: center;
        }
    }
}
