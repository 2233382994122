/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ProductInformation {
    &-Description {
        font-family: Lato, sans-serif;

        @include mobile {
            margin-inline-start: 45px;
            margin-inline-end: 30px;
        }

        @include desktop {
            margin-inline-start: 25px;
        }
        
        line-height: 18px;
        text-align: start;
        color: #223041;
        font-weight: 600;

        .descripcion-resumen {
            .shwmre {
                padding: 0 3px;
                cursor: pointer;
                opacity: 0.7;
                font-size: 10px;
                text-decoration: underline;
                white-space: nowrap;
            }
        }
    }

    &-Wrapper {
        padding: 0;
    }

    &-Content {
        margin-block-end: 0;
    }
}
